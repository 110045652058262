import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { usePopup } from "components/popups/usePopup";
import SingleDatePicker from "components/shared/SingleDatePicker";
import { Button, Checkbox, Dropdown } from "components/ui";
import {
  CANCELLATION_REASONS_MAP,
  EVENT_TYPES,
  SESSION_CODE_MAPPING,
} from "constants/scheduling.constant";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bulkCancelSessions } from "services/SchedulerService";
import { setRefreshSessions } from "store/base/commonSlice";
import { sortSessionCodesByPrimaryCode } from "utils/scheduling";

const AddEvent = () => {
  const dispatch = useDispatch();
  const sessionCodes = useSelector((state) => state.auth.user.sessionCodes);
  const settings = useSelector((s) => {
    return s.base.common.settings;
  });
  const { hidePopup } = usePopup();
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (selectedReason === null) {
        toast.error("Please select a reason for bulk cancellation");
        return;
      }
      await bulkCancelSessions({
        fromDate: date,
        toDate: date,
        cancelReason: selectedReason,
        placeholderSCode: selectedAppointmentType?.id ?? null,
        cancelBy: "clinic",
      });
      toast.success("Bulk Cancellation Successful");
      hidePopup("Event");
      dispatch(setRefreshSessions(true));
      setChecked(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderSessionIDDropdown = () => {
    return (
      <div>
        <div className="mb-3 font-semibold">
          Placeholder Session Code (Optional)
        </div>
        <Dropdown
          fullWidth
          placement="top-start"
          renderTitle={
            <div className="border-1 flex min-w-[192px] cursor-pointer items-center justify-between border px-4 py-2 text-sm shadow">
              <div className="truncate">
                {selectedAppointmentType
                  ? `${selectedAppointmentType?.primaryCode} - ${selectedAppointmentType?.subCode}`
                  : "Select Session Code"}
              </div>
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5 text-base text-gray-300"
                aria-hidden="true"
              />
            </div>
          }
          className="h-96 w-full"
          menuClass="max-h-[400px] overflow-y-scroll w-full overflow-x-hidden"
        >
          {sessionCodes
            .filter(
              (item) =>
                item?.id === SESSION_CODE_MAPPING.holiday ||
                item?.id === SESSION_CODE_MAPPING.closed,
            )
            .sort(sortSessionCodesByPrimaryCode)
            .map((item, index) => (
              <Dropdown.Item
                key={item.id}
                eventKey={item.id}
                onSelect={() => {
                  setSelectedAppointmentType(item);
                }}
                className={`w-full rounded-none border-b px-0`}
              >
                <div className={"flex w-full gap-4"}>
                  <div className="relative flex pl-4 font-bold">
                    {item?.primaryCode}
                  </div>
                  <div className="truncate pr-6">{item?.subCode}</div>
                </div>
              </Dropdown.Item>
            ))}
        </Dropdown>
      </div>
    );
  };

  return (
    <div>
      <div className="flex w-full justify-center gap-8">
        <div className="flex-1">
          <div className="font-semibold">Event Type</div>
          <div className="text-xs italic text-dark">
            Select type of event you want to create
          </div>
        </div>{" "}
        <div className="flex-1">
          <Dropdown
            fullWidth
            placement="top-bottom"
            renderTitle={
              <div className="border-1 flex min-w-[192px] cursor-pointer items-center justify-between border px-4 py-2 text-sm shadow">
                <div className="truncate">
                  {selectedEventType
                    ? `${selectedEventType.title}`
                    : "Select Event Type"}
                </div>
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5 text-base text-gray-300"
                  aria-hidden="true"
                />
              </div>
            }
            className="h-96 w-full"
            menuClass="max-h-[400px] overflow-y-scroll w-full overflow-x-hidden"
          >
            {EVENT_TYPES.map((event) => (
              <Dropdown.Item
                key={event.id}
                eventKey={event.id}
                onSelect={() => setSelectedEventType(event)}
                className="w-full"
              >
                <div className={"flex w-full gap-4"}>
                  <div className="truncate">{event.title}</div>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      </div>
      {selectedEventType?.value === "bulk_cancellation" && (
        <div className="flex flex-col gap-8">
          <div className="mt-8 flex w-full justify-center">
            <div className="flex flex-1 items-center gap-8">
              <div className="flex-1">
                <div className="font-semibold">Date </div>
                <div className="text-xs italic text-dark">
                  Select date to bulk cancel all sessions
                </div>
              </div>
              <div className="flex-1">
                <SingleDatePicker
                  isOutsideRange={(day) => day.isBefore(moment())}
                  onlyDate={true}
                  currentTitle={moment(date).format("ddd, MMM D, YYYY")}
                  handleChange={(type, date) => {
                    setChecked(false);
                    if (type === "date") {
                      setDate(date);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center gap-8">
            <div className="flex-1">
              <div className="font-semibold">Cancellation Reason</div>
              <div className="text-xs italic text-dark">
                Select reason for bulk cancellation
              </div>
            </div>{" "}
            <div className="flex-1">
              <Dropdown
                fullWidth
                placement="top-bottom"
                renderTitle={
                  <div className="border-1 flex min-w-[192px] cursor-pointer items-center justify-between border px-4 py-2 text-sm shadow">
                    <div className="truncate">
                      {selectedReason || "Select Reason"}
                    </div>
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5 text-base text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                }
                className="h-96 w-full"
                menuClass="max-h-[400px] overflow-y-scroll w-full overflow-x-hidden"
              >
                {(
                  settings?.settings?.cancelReasons?.clinic ||
                  CANCELLATION_REASONS_MAP.clinic
                ).map((reason) => (
                  <Dropdown.Item
                    key={reason}
                    eventKey={reason}
                    onSelect={() => setSelectedReason(reason)}
                    className="w-full"
                  >
                    <div className={"flex w-full gap-4"}>
                      <div className="truncate">{reason}</div>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </div>
          {sessionCodes.filter(
            (item) =>
              item?.id === SESSION_CODE_MAPPING.holiday ||
              item?.id === SESSION_CODE_MAPPING.closed,
          ).length > 0 && renderSessionIDDropdown()}
          <div className="mb-8 mt-24 flex w-full items-center justify-center">
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />{" "}
            <span>
              I confirm that I want to cancel all sessions of{" "}
              {moment(date).format("ddd, MMM D, YYYY")}
            </span>
          </div>
        </div>
      )}

      <div className="mt-8 flex justify-center">
        <Button
          variant="solid"
          onClick={handleSubmit}
          color="primary"
          shape="none"
          disabled={!checked}
          loading={isLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddEvent;
