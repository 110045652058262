import "./locales";

import Layout from "components/layout";
import { PopupProvider } from "components/popups/PopupContext";
import PopupManager from "components/popups/PopupManager";
import Theme from "components/template/Theme";
import React from "react";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import history from "./history";
import store, { persistor } from "./store";

const tagManagerArgs = {
  gtmId: "GTM-PC3V4TC3",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Theme>
            <PopupProvider>
              <Layout />
              <PopupManager />
            </PopupProvider>
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
