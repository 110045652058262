import {
  DEFAULT_ADDITIONAL_SESSION_OBJECT,
  SESSION_CODE_MAPPING,
} from "constants/scheduling.constant";

import { getIndexFromDay } from "./date";
import { toFixedIfNecessary } from "./math";

export const checkForGroupTherapy = (sessions) => {
  console.log(sessions);
  let isGroupTherapy = false;
  sessions.forEach((session) => {
    console.log(session);
    if (
      session.SessionCode === SESSION_CODE_MAPPING.groupTherapy ||
      session.SessionCode === SESSION_CODE_MAPPING.groupTherapyBCBA
    ) {
      isGroupTherapy = true;
    }
  });
  return isGroupTherapy;
};

export const containsBCBAStaff = (sessions) => {
  let containsBCBA = false;
  console.log(sessions);
  sessions.forEach((session) => {
    if (session.StaffRole === "BCBA") {
      containsBCBA = true;
    }
  });
  console.log(containsBCBA);
  return containsBCBA;
};

export const getSessionCodeData = (sessionCode, sessionCodes) => {
  const sessionCodeData = sessionCodes.find(
    (sessionCodeData) => sessionCodeData.id === sessionCode,
  );
  return sessionCodeData;
};

export const sortSessionCodesByPrimaryCode = (a, b) => {
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  return 0;
};

export const getClientsFromSessions = (sessions) => {
  let clientMap = new Map();
  sessions.forEach((s) => {
    if (!clientMap.has(s.clientId) && s?.ClientOnboard?.childName) {
      clientMap.set(s.clientId, {
        title: s.ClientOnboard?.childName,
        PodMappings: s.ClientOnboard?.PodMappings,
      });
    }
  });
  let clients = Array.from(clientMap, ([value, label]) => ({
    id: value,
    title: label.title,
    PodMappings: label.PodMappings,
  }));

  return clients;
};

export const getStaffFromSessions = (sessions) => {
  let staffMap = new Map();
  sessions.forEach((s) => {
    if (!staffMap.has(s.staffId) && s?.ClinicStaff?.firstName) {
      staffMap.set(
        s.staffId,
        s.ClinicStaff?.firstName + " " + s.ClinicStaff?.lastName,
      );
    }
  });
  let staff = Array.from(staffMap, ([value, label]) => ({
    id: value,
    title: label,
  }));

  return staff;
};

export const generateRecurringSessionsFromDataForClient = (data) => {
  let recurringSessions = [];
  let i = 1;
  data.forEach((d) => {
    const { clientId, staffId, booking, sessionCode, staff, client } = d;
    const daysOfWeek = Object.keys(booking);
    daysOfWeek.forEach((day) => {
      booking[day].forEach((time) => {
        const { startTime, endTime } = time;
        const session = {
          daysOfWeek: [getIndexFromDay(day)],
          id: i++,
          billable: true,
          title: staff?.firstName + " " + staff?.lastName,
          subTitle: `${startTime} to ${endTime}`,
          startTime: startTime,
          endTime: endTime,
          resourceId: clientId,
          staffId: staffId,
          staffName: staff?.firstName + " " + staff?.lastName,
          clientName: client?.childName,
          sessionCode: time?.sessionCode || sessionCode,
          pos: time?.pos,
        };
        recurringSessions.push(session);
      });
    });
  });
  return recurringSessions;
};

export const generateRecurringSessionsFromDataForStaff = (data) => {
  let recurringSessions = [];
  let i = 1;
  data.forEach((d) => {
    const { clientId, staffId, booking, staff, client } = d;
    const daysOfWeek = Object.keys(booking);
    daysOfWeek.forEach((day) => {
      booking[day].forEach((time) => {
        const { startTime, endTime } = time;
        const sessionCode = time?.sessionCode;
        const session = {
          daysOfWeek: [getIndexFromDay(day)],
          id: i++,
          billable:
            sessionCode === SESSION_CODE_MAPPING.directCare ? true : false,
          title:
            sessionCode === SESSION_CODE_MAPPING.directCare
              ? client?.childName
              : sessionCode,
          subTitle: `${startTime} to ${endTime}`,
          startTime: startTime,
          endTime: endTime,
          resourceId: staffId,
          clientId: clientId,
          staffName: staff?.firstName + " " + staff?.lastName,
          clientName: client?.childName,
          sessionCode: time?.sessionCode || sessionCode,
          pos: time?.pos,
        };
        recurringSessions.push(session);
      });
    });
  });
  return recurringSessions;
};

export const getClientsFromRecurringSessions = (sessions) => {
  let clientMap = new Map();
  console.log(sessions);
  sessions.forEach((s) => {
    if (!clientMap.has(s.resourceId) && s?.clientName) {
      clientMap.set(s.resourceId, s.clientName);
    }
  });
  let clients = Array.from(clientMap, ([value, label]) => ({
    id: value,
    title: label,
  }));
  return clients;
};

export const getStaffFromRecurringSessions = (sessions) => {
  let staffMap = new Map();
  sessions.forEach((s) => {
    if (!staffMap.has(s.resourceId) && s?.staffName) {
      staffMap.set(s.resourceId, s.staffName);
    }
  });
  let staffs = Array.from(staffMap, ([value, label]) => ({
    id: value,
    title: label,
  }));
  return staffs;
};

export const convertLocationObjectToString = (location) => {
  if (!location) return "";
  return location?.pos === 10 ? "Telehealth" 
  : `${location?.line1??""}, ${location?.line2??""}, ${location?.city??""}, ${location?.region??""}, ${location?.zipcode??""}`;
};

export const generateAdditionalSessionObject = (
  sessionData,
  startTime,
  endTime,
) => {
  const currentDate = new Date(sessionData?.startTs);
  currentDate.setHours(startTime.split(":")[0], startTime.split(":")[1]);
  const localStartTs = new Date(currentDate);
  const startTs = new Date(
    localStartTs.getTime() - localStartTs.getTimezoneOffset() * 60000,
  );
  currentDate.setHours(endTime.split(":")[0], endTime.split(":")[1]);
  const localEndTs = new Date(currentDate);
  const endTs = new Date(
    localEndTs.getTime() - localEndTs.getTimezoneOffset() * 60000,
  );

  let sessionCodeId = DEFAULT_ADDITIONAL_SESSION_OBJECT.sessionCodeId;
  let duration = DEFAULT_ADDITIONAL_SESSION_OBJECT.duration;
  if (startTs.getTime() > new Date(sessionData?.startTs).getTime()) {
    sessionCodeId = "LTARCL";
    duration = startTs.getTime() - new Date(sessionData?.startTs).getTime();
  } else if (endTs.getTime() > new Date(sessionData?.endTs).getTime()) {
    sessionCodeId = "LATEPCKP";
    duration = endTs.getTime() - new Date(sessionData?.endTs).getTime();
  } else if (endTs.getTime() < new Date(sessionData?.endTs).getTime()) {
    sessionCodeId = "ERLYPCKP";
    duration = new Date(sessionData?.endTs).getTime() - endTs.getTime();
  }
  const minutes = Math.floor(duration / 60000);
  return { sessionCodeId, duration: minutes };
};

export const generateFilters = (sessions) => {
  let sessionCodes = [];
  let status = [
    {
      id: "upcoming",
      title: "Active",
    },
    {
      id: "cancel",
      title: "Canceled",
    },
    {
      id: "cancelby-client",
      title: "By Client",
    },
    {
      id: "cancelby-staff",
      title: "By Staff",
    },
    {
      id: "cancelby-clinic",
      title: "By Clinic",
    },
    {
      id: "cancel-pending",
      title: "Cancel Pending",
    },
  ];
  let ehrRequestStatus = [
    {
      id: "pending",
      title: "Pending",
    },
    {
      id: "success",
      title: "Success",
    },
    {
      id: "failed",
      title: "Failed",
    },
    {
      id: "in-queue",
      title: "In Queue",
    },
  ];
  let pos = [
    {
      id: 3,
      title: "School",
    },
    {
      id: 11,
      title: "Clinic",
    },
    {
      id: 12,
      title: "Home",
    },
    {
      id: 99,
      title: "Other",
    },
    {
      id: 10,
      title: "Telehealth",
    }
  ];
  sessions.forEach((session) => {
    if (!sessionCodes.includes(session.sessionCode)) {
      sessionCodes.push(session.sessionCode);
    }
  });
  return { sessionCodes, status, ehrRequestStatus, pos };
};

function getTimeToInt(time) {
  return parseInt(time.substr(0, 2)) * 60 + parseInt(time.substr(3, 5));
}

export const getTotalHours = (bookings) => {
  let totalHours = 0;
  Object.keys(bookings).forEach((day) => {
    bookings[day].forEach((booking) => {
      const startTime = getTimeToInt(booking.startTime);
      const endTime = getTimeToInt(booking.endTime);
      totalHours += (endTime - startTime) / 60;
    });
  });
  return toFixedIfNecessary(totalHours, 2);
};
