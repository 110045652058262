import ApiService from "./ApiService";

export async function getNewClientDataById(clientId) {
  return ApiService.fetchData({
    url: `/clients/id`,
    method: "post",
    data: { clientId },
  });
}

export async function getAllNewClientData(params) {
  return ApiService.fetchData({
    url: `/clients/list`,
    method: "get",
    params,
  });
}

export async function updateClientConstraints(
  clientId,
  hardConstraints,
  softConstraints,
) {
  return ApiService.fetchData({
    url: `/clients/constraints`,
    method: "post",
    data: {
      clientId,
      hardConstraints,
      softConstraints,
    },
  });
}

export async function updateNewClientData(data) {
  return ApiService.fetchData({
    url: `/clients/details-update`,
    method: "post",
    data,
  });
}
export async function updateNewClientSchedule(data) {
  return ApiService.fetchData({
    url: `/clients/schedule-update`,
    method: "post",
    data,
  });
}

export async function updateStaffSchedule(data) {
  return ApiService.fetchData({
    url: `/staff/schedule-update`,
    method: "post",
    data,
  });
}

export async function deleteClientSchedule(data) {
  return ApiService.fetchData({
    url: `/clients/schedule-delete`,
    method: "post",
    data,
  });
}
export async function createNewClientData(data) {
  return ApiService.fetchData({
    url: `/clients/add`,
    method: "post",
    data,
  });
}

export async function disableClient(data) {
  return ApiService.fetchData({
    url: `/clients/disable`,
    method: "post",
    data,
  });
}

export async function getAvailableSlots(data) {
  return ApiService.fetchData({
    url: `/clinic-staff/availability/id`,
    method: "post",
    data,
  });
}

export async function getClientsAvailableSlots(data) {
  return ApiService.fetchData({
    url: `/clinic-staff/client-availability/id`,
    method: "post",
    data,
  });
}

export async function getWeeklyShiftsById(data) {
  return ApiService.fetchData({
    url: `/clinic-staff/shift/id`,
    method: "post",
    data,
  });
}

export async function saveWeeklyShiftsById(data) {
  return ApiService.fetchData({
    url: `/clinic-staff/shift/add`,
    method: "post",
    data,
  });
}

export async function getWeeklyScheduleById(data) {
  return ApiService.fetchData({
    url: `/staff/getSchedule`,
    method: "post",
    data,
  });
}

export async function getWeeklyScheduleForStaff(data) {
  return ApiService.fetchData({
    url: `/staff/getScheduleForStaff`,
    method: "post",
    data,
  });
}

export async function saveWeeklyNonBillableScheduleById(data) {
  return ApiService.fetchData({
    url: `/staff/upsertNonBillSchedule`,
    method: "post",
    data,
  });
}

export async function getAllShifts(data) {
  return ApiService.fetchData({
    url: `/clinic-staff/shift/list`,
    method: "get",
    data,
  });
}

export async function getAllBookings() {
  return ApiService.fetchData({
    url: `/clinic-staff/schedule/list`,
    method: "get",
  });
}

export async function getAllAvailability() {
  return ApiService.fetchData({
    url: `/clinic-staff/availability/list`,
    method: "get",
  });
}

export async function getSessions({ ehrData, fromDate, toDate, podId }) {
  return ApiService.fetchData({
    url: `/sessions/list?fromDate=${fromDate}&toDate=${toDate}&ehrData=${
      ehrData ?? false
    }&podId=${podId ?? ""}`,
    method: "post",
  });
}

export async function cancellationByClient(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/request`,
    method: "post",
    data,
  });
}
export async function cancellationByTech(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/request`,
    method: "post",
    data,
  });
}
export async function cancellationByAdmin(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/request`,
    method: "post",
    data,
  });
}
/**
 * Common method to cancel session.
 * Action {pending,cancelled} depends on the user role.
 */
export async function cancelSession(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/request`,
    method: "post",
    data, // {sessionId, reason}
  });
}

export async function rejectCancellation(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/reject`,
    method: "post",
    data, // {sessionId}
  });
}

export async function removeSession(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/remove`,
    method: "post",
    data, // {sessionId}
  });
}

export async function cancelledSessions() {
  return ApiService.fetchData({
    url: `/sessions/cancellation/list`,
    method: "get",
  });
}

export async function getConflicts() {
  return ApiService.fetchData({
    url: `/sessions/conflicts`,
    method: "get",
  });
}

export async function swapSessionForTech(sessionId) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/single/?id=${sessionId}`,
    method: "get",
  });
}

export async function getClientGaps(date, gapMinThreshold = 30) {
  return ApiService.fetchData({
    url: `sessions/clients/gaps?gapMinThreshold=${gapMinThreshold}${
      date ? `&date=${date}` : ""
    }`,
    method: "get",
  });
}

export async function multiSwapSessionForTech(sessionId) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/multi/?id=${sessionId}`,
    method: "get",
  });
}

export async function bcbaSwapSessionForTech(sessionId) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/bcba/?id=${sessionId}`,
    method: "get",
  });
}

export async function getSwapOptions(params) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap`,
    method: "get",
    params,
  });
}

export async function createSwapSession(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/single`,
    method: "post",
    data,
  });
}

export async function createMultiSwapSessions(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/multi`,
    method: "post",
    data,
  });
}

export async function createBcbaSwapSessions(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap/bcba`,
    method: "post",
    data,
  });
}

export async function createSwapSessions(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/swap`,
    method: "post",
    data,
  });
}

export async function approveCancellation(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/approve`,
    method: "post",
    data,
  });
}

export async function createNewSession(data) {
  return ApiService.fetchData({
    url: `/sessions/create`,
    method: "post",
    data,
  });
}

export async function updateSession(data) {
  return ApiService.fetchData({
    url: `/sessions/update`,
    method: "post",
    data,
  });
}

export async function getClientBilledSessionsByStaff(data) {
  return ApiService.fetchData({
    url: `/sessions/client/staff-aggregate`,
    method: "get",
    data,
  });
}

export async function getAvailableStaff(data) {
  return ApiService.fetchData({
    url: `/sessions/available/staffs`,
    method: "post",
    data,
  });
}

export async function getAvailableClients(data) {
  return ApiService.fetchData({
    url: `/sessions/available/clients`,
    method: "post",
    data,
  });
}

export async function getCancellationHistory(data) {
  return ApiService.fetchData({
    url: `/sessions/cancellation/history`,
    method: "get",
    data,
  });
}

export async function getConstants() {
  return ApiService.fetchData({
    url: `/mapping/constants`,
    method: "get",
  });
}

export async function getScheduleExpirations() {
  return ApiService.fetchData({
    url: `/clients/expirations?dayRange=20`,
    method: "get",
  });
}

export async function getFullStaffOptimizeOptions(data) {
  return ApiService.fetchData({
    url: `/sessions/getFullStaffOptimizeOptions`,
    method: "post",
    data,
  });
}

export async function getFullStaffOptimizeOptionsV2(data) {
  return ApiService.fetchData({
    url: `/fso/day/getOptions`,
    method: "post",
    data,
  });
}

export async function getFullStaffOptimizationResults(data) {
  return ApiService.fetchData({
    url: `/fso/day/solve`,
    method: "post",
    data,
    timeout: 420000,
  });
}

export async function confirmFullStaffOptimization(data) {
  return ApiService.fetchData({
    url: `/sessions/FullStaffOptimizeConfirm`,
    method: "post",
    data,
  });
}

export async function getFSOSchOptions(data) {
  return ApiService.fetchData({
    url: `/sessions/getFSOSchOptions`,
    method: "post",
    data,
  });
}

export async function getFSOSchResults(data) {
  return ApiService.fetchData({
    url: `/sessions/fsoSchSolve`,
    method: "post",
    data,
    timeout: 900000,
  });
}

export async function confirmFSOSch(data) {
  return ApiService.fetchData({
    url: `/sessions/fsoSchConfirm`,
    method: "post",
    data,
  });
}

export async function getBCBARecommendationOptions(data) {
  return ApiService.fetchData({
    url: `/bcba-recommendation/get-options`,
    method: "post",
    data,
  });
}

export async function getBCBARecommendationResults(data) {
  return ApiService.fetchData({
    url: `/bcba-recommendation/solve`,
    method: "post",
    data,
  });
}

export async function saveBCBARecommendation(data) {
  return ApiService.fetchData({
    url: `/bcba-recommendation/save`,
    method: "post",
    data,
  });
}

export async function saveNotes(data) {
  return ApiService.fetchData({
    url: `/clients/add-notes`,
    method: "post",
    data,
  });
}

export async function getBeforeAfterSessions(params) {
  return ApiService.fetchData({
    url: `/sessions/getBeforeAfterSessions`,
    method: "get",
    params,
  });
}

export async function bulkCancelSessions(data) {
  return ApiService.fetchData({
    url: `/sessions/bulkCancel`,
    method: "post",
    data,
  });
}

export async function getClientCustomStatus(params) {
  return ApiService.fetchData({
    url: `/clients/get-custom-status`,
    method: "get",
    params,
  });
}

export async function getClientMakeUpOptions(params) {
  return ApiService.fetchData({
    url: `/clients/get-makeup-options`,
    method: "get",
    params,
  });
}

export async function saveMakeUpOptions(data) {
  return ApiService.fetchData({
    url: `/clients/save-makeup-options`,
    method: "post",
    data,
  });
}
