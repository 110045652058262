export function toFixedIfNecessary(value, dp) {
  if (isNaN(value)) {
    return 0;
  }
  return +parseFloat(value).toFixed(dp);
}

export function formatToTwoDecimals(value) {
  if (value % 1 !== 0 && value.toString().split(".")[1]?.length > 2) {
    return value.toFixed(2);
  }
  return value;
}

export function formatToTwoDecimalsNumber(value) {
  if (value % 1 !== 0 && value.toString().split(".")[1]?.length > 2) {
    return Number(value.toFixed(2));
  }
  return value;
}
