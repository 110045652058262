import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSettings } from "services/Clinic";
import { getConstants, getFeatureAccess } from "services/Information";

export const fetchConstants = createAsyncThunk("getConstants", async (data) => {
  const response = await getConstants(data);
  return response.data;
});

export const fetchFeatureAccess = createAsyncThunk(
  "getFeatureAccess",
  async (data) => {
    const response = await getFeatureAccess(data);
    console.log(response);
    return response.data;
  },
);

export const fetchSettings = createAsyncThunk("getSettings", async (data) => {
  const response = await getSettings(data);
  return response.data;
});

export const initialState = {
  currentRouteKey: "",
  constants: {},
  featureAccess: {},
  settings: {},
  refreshSessions: false,
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setConstants: (state, action) => {
      state.constants = action.payload;
    },
    setFeatureAccess: (state, action) => {
      state.featureAccess = action.payload;
    },
    setRefreshSessions: (state, action) => {
      state.refreshSessions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
  },
});

export const {
  setCurrentRouteKey,
  setConstants,
  setFeatureAccess,
  setRefreshSessions,
} = commonSlice.actions;

export default commonSlice.reducer;
