import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ graphData, showLegend = false, centerElement }) => {
  return (
    <div className="relative w-full">
      {centerElement && (
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          {centerElement}
        </div>
      )}
      <Doughnut
        data={graphData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: "70%",
          plugins: {
            legend: {
              display: showLegend,
              onClick: null,
              position: "bottom",
              align: "center",
            },
          },
        }}
      />
    </div>
  );
};

export default PieChart;
