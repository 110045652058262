import AddEvent from "components/forms/AddEvent";
import { ConfirmDialog } from "components/shared";

import { usePopup } from "./usePopup";

const PopupManager = () => {
  const { popups, hidePopup } = usePopup();

  const renderPopupContent = (id: string, props: any) => {
    switch (id) {
      case "Event":
        return <AddEvent {...props} />;
      // case "popup2":
      //   return <Popup2 {...props} />;
      default:
        return null;
    }
  };

  return (
    <>
      {popups.map(({ id, props }) => (
        <ConfirmDialog
          isOpen={true}
          key={id}
          onClose={() => {
            hidePopup(id);
          }}
          onRequestClose={() => {
            hidePopup(id);
          }}
          type={props?.type}
          title={props?.title}
          onCancel={() => {
            hidePopup(id);
          }}
          width={props?.width}
        >
          {renderPopupContent(id, props)}
        </ConfirmDialog>
      ))}
    </>
  );
};

export default PopupManager;
