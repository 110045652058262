import axios from "axios";
import appConfig from "configs/app.config";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";
import { onSignOutSuccess } from "store/auth/sessionSlice";

const getStore = () => require("../store").default;

const unauthorizedCode = [403];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
});

BaseService.interceptors.request.use(
  (config) => {
    const store = getStore();
    const accessToken = store?.getState()?.auth?.session?.token;

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    try {
      const store = getStore();
      if (store && response && unauthorizedCode.includes(response.status)) {
        console.log("Expired Token request --> LOGOUT");
        store?.dispatch(onSignOutSuccess());
      }else if(response && response.status === 401){
        console.log("Unauthorized request --> ?");
      }

    } catch (e) {
      console.error("Error accessing store:", e);
    }
    return Promise.reject(error);
  },
);

export default BaseService;
