import React, { createContext, ReactNode, useState } from "react";

type PopupProps = {
  type: string;
  title: string;
  isOpen: boolean;
  width?: string;
  onClose: () => void;
  [key: string]: any;
};
type Popup = { id: string; props: PopupProps };

interface PopupContextType {
  popups: Popup[];
  showPopup: (id: string, props: PopupProps) => void;
  hidePopup: (id: string) => void;
}

export const PopupContext = createContext<PopupContextType | null>(null);

export const PopupProvider = ({ children }: { children: ReactNode }) => {
  const [popups, setPopups] = useState<Popup[]>([]);

  const showPopup = (id: string, props: PopupProps) => {
    setPopups((prev) => [...prev, { id, props }]);
  };

  const hidePopup = (id: string) => {
    setPopups((prev) => prev.filter((popup) => popup.id !== id));
  };

  return (
    <PopupContext.Provider value={{ popups, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  );
};
