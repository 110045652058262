import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import React, { useState } from "react";
import { DayPickerSingleDateController } from "react-dates";
import { BsCalendar } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getLocalDate } from "utils/date";

function SingleDatePicker({
  isOutsideRange,
  currentTitle,
  handleChange,
  isLoading,
  onlyDate,
}) {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="relative w-full sm:w-auto">
      <div className="flex items-stretch">
        {!onlyDate && (
          <button
            onClick={() => {
              handleChange("prev");
            }}
            className={
              "font-regular rounded-l border border-stroke px-4 py-3 text-primary hover:bg-stroke"
            }
            disabled={isLoading}
          >
            <FaChevronLeft />
          </button>
        )}

        <button
          className={`flex flex-1 items-center gap-2 border-y border-stroke px-4 hover:bg-stroke sm:min-w-[180px] ${onlyDate ? "flex-row-reverse justify-between border py-2 text-sm shadow" : "justify-center text-xs font-medium text-primary"}`}
          onClick={() => {
            setShowCalendar(!showCalendar);
          }}
          disabled={isLoading}
        >
          <BsCalendar />
          {moment(currentTitle).isValid()
            ? moment(currentTitle).format("ddd, MMM D, YYYY")
            : currentTitle}
        </button>

        {!onlyDate && (
          <button
            onClick={() => {
              handleChange("next");
            }}
            className={
              "font-regular rounded-r border border-stroke px-4 py-3 text-primary hover:bg-stroke"
            }
            disabled={isLoading}
          >
            <FaChevronRight />
          </button>
        )}
      </div>

      <div
        className={`absolute right-0 z-10 mt-2 overflow-hidden rounded-lg bg-white text-sm transition-all ${
          showCalendar ? "w-auto border shadow" : "w-0"
        }`}
      >
        <DayPickerSingleDateController
          isOutsideRange={isOutsideRange || (() => false)}
          wrapperClassName="w-full"
          date={moment(currentTitle)}
          hideKeyboardShortcutsPanel={true}
          onOutsideClick={() => {
            if (showCalendar) {
              setShowCalendar(false);
            }
          }}
          onDateChange={(date) => {
            const formattedDate = getLocalDate(new Date(date));
            handleChange("date", formattedDate);
            setShowCalendar(false);
          }}
        />
      </div>
    </div>
  );
}

export default SingleDatePicker;
