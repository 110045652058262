import ApiService from "./ApiService";

export async function addMetaData(data) {
  return ApiService.fetchData({
    url: "/user/metadata",
    method: "post",
    data,
  });
}

export async function addRbtProfileData(data) {
  return ApiService.fetchData({
    url: "rbt/profile",
    method: "post",
    data,
  });
}

export async function addClinicIntrest(data) {
  return ApiService.fetchData({
    url: "/clinic/interest",
    method: "post",
    data,
  });
}

export async function getProfileByClinicId(params) {
  return ApiService.fetchData({
    url: "/user/metadata",
    method: "get",
    params,
  });
}

export async function getConstants() {
  return ApiService.fetchData({
    url: `/mapping/constants`,
    method: "get",
  });
}

export async function getLocationsByClientId(params) {
  return ApiService.fetchData({
    url: "/mapping/locations/client",
    method: "get",
    params,
  });
}

export async function getSessionCodes() {
  return ApiService.fetchData({
    url: `/mapping/constants/sessionCodes`,
    method: "get",
  });
}

export async function getFeatureAccess(params) {
  return ApiService.fetchData({
    url: "/clinic/feature-access",
    method: "get",
    params,
  });
}

export async function searchStaffEHRId(data) {
  return ApiService.fetchData({
    url: "/ehr/searchStaff",
    method: "post",
    data,
  });
}

export async function searchClientEHRId(data) {
  return ApiService.fetchData({
    url: "/ehr/searchClient",
    method: "post",
    data,
  });
}

export async function retriggerEHR(data) {
  return ApiService.fetchData({
    url: "/ehr/retrigger",
    method: "post",
    data,
  });
}

export async function retryBulkEHR(data) {
  return ApiService.fetchData({
    url: "/ehr/retryEhrClientOrStaffFailures",
    method: "post",
    data,
  });
}

export async function retryAllEHRForClientOrStaff(data) {
  return ApiService.fetchData({
    url: "/ehr/retryEhrClientOrStaffFailures",
    method: "post",
    data,
  });
}

export async function fetchNewClinicClients(data) {
  return ApiService.fetchData({
    url: "/ehr/fetchAndStoreClinicClients",
    method: "post",
    data,
  });
}

export async function fetchNewClinicStaff(data) {
  return ApiService.fetchData({
    url: "/ehr/fetchAndStoreClinicStaffs",
    method: "post",
    data,
  });
}

export async function getSessionNotes(params) {
  return ApiService.fetchData({
    url: "/sessions/notes",
    method: "get",
    params,
  });
}

export async function fetchAppNotifications(params) {
  return ApiService.fetchData({
    url: "/notification/app-notification",
    method: "get",
    params,
  });
}
