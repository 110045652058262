import moment from "moment";

export const formatDateToShortDayAndDate = (date) => {
  const options = { weekday: "short", day: "2-digit", month: "2-digit" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const dayPart = formattedDate.slice(0, 3);
  const restOfDate = formattedDate.slice(4);
  const formattedDayDate = `${dayPart}${restOfDate}`;
  return formattedDayDate;
};

export function formatHoursMinutes(hours) {
  const totalMinutes = Math.round(hours * 60);
  const h = Math.floor(totalMinutes / 60);
  const m = totalMinutes % 60;
  return `${h}h ${m}m`;
}

export const formatDateToHourMinAndTZ = (date) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
    timeZoneName: "short",
  };
  const formattedDate = date.toLocaleString("en-GB", options);
  const timePart = formattedDate.slice(0, 5);
  const restOfTime = formattedDate.slice(6, 8);
  const formattedTimeDate = `${timePart}${restOfTime}`;
  return formattedTimeDate;
};

export const formatDateToHHMM = (date) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };
  const formattedDate = date.toLocaleString("en-GB", options);
  const timePart = formattedDate.slice(0, 5);
  const restOfTime = formattedDate.slice(6);
  const formattedTimeDate = `${timePart} ${restOfTime.toUpperCase()}`;
  if (formattedTimeDate.slice(0, 2) === "00") {
    return `12${formattedTimeDate.slice(2)}`;
  }
  return formattedTimeDate;
};

export function formatDateTo12HourFormat(date) {
  // Ensure the input is a Date object
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };

  const formattedDate = date.toLocaleString("en-GB", options);

  if (date.getHours() === 0 && date.getMinutes() === 0) {
    options.hour = "numeric"; // Display "12" instead of "00"
  }
  const timePart = formattedDate.slice(0, 5);
  const restOfTime = formattedDate.slice(6).toString().toUpperCase();
  const formattedTimeDate = `${timePart} ${restOfTime}`;
  if (formattedTimeDate.slice(0, 2) === "00") {
    return `12${formattedTimeDate.slice(2)}`;
  }
  return formattedTimeDate;
}

export function formatDateToMMDDYYYY(date) { 
  // this function is not correct for UTC date
  // const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are 0-based
  // const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are 0-based
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${month}/${day}/${year}`;
}

// export function formatUTCDateToMMDDYYYY(date) { 
//   const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are 0-based
//   const day = date.getUTCDate().toString().padStart(2, "0");
//   const year = date.getFullYear().toString();

//   return `${month}/${day}/${year}`;
// }


export function formatDateToMonthDateYear(date, shortMonth = false) {
  // Create an array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month, date, and year components from the date object
  const month = shortMonth
    ? shortMonthNames[date.getMonth()]
    : monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Combine the components into the desired format
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

export function formatDateToHHMMTZ(date) {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
    timeZoneName: "short",
  };
  const formattedDate = date.toLocaleString("en-GB", options);
  const timePart = formattedDate.slice(0, 5);
  const restOfTime = formattedDate.slice(6, 8).toUpperCase();
  const formattedTimeDate = `${timePart} ${restOfTime}`;
  if (formattedTimeDate.slice(0, 2) === "00") {
    return `12${formattedTimeDate.slice(2)}`;
  }
  return formattedTimeDate;
}

/**
 * convert 24 hr date format to 12 hr format
 */
export const convertStr24HrTo12Hr = (time24hr) => {
  if (!time24hr) return time24hr;
  const [hours, minutes] = time24hr.split(":").map(Number);
  const date = new Date();
  date.setUTCHours(hours, minutes);
  return formatDateTo12HourFormat(date);
};

export function formatYYYYMMDDtoMMDDYYYY(inputDate) {
  // Split the input string by the '-' delimiter
  const parts = inputDate.split("-");

  // Rearrange the parts in the desired format
  const formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;

  return formattedDate;
}

export function getDurationInHours(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error("Invalid date format");
  }

  const durationInMilliseconds = end - start;
  return durationInMilliseconds / (1000 * 60 * 60);
}

export const calculateDurationInYYYrsMMMonths = (startDate) => {
  const now = new Date();
  const start = new Date(startDate);
  // console.log(start);
  const years = now.getFullYear() - start.getFullYear();
  if (years === 0) {
    const months = now.getMonth() - start.getMonth();
    return `${months} mos`;
  }
  if (now.getMonth() === start.getMonth()) {
    return `${years} yrs`;
  }
  if (now.getMonth() < start.getMonth()) {
    const months = now.getMonth() - start.getMonth() + 12;
    return `${years - 1} yrs ${months} mos`;
  } else {
    const months = now.getMonth() - start.getMonth();
    return `${years} yrs ${months} mos`;
  }
};

export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function getWeekRangeString(date) {
  const currentDate = new Date(date);
  const dayOfWeek = currentDate.getDay();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - dayOfWeek);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(23, 59, 59, 999);

  const options = { month: "2-digit", day: "2-digit", year: "numeric" };

  const startString = formatDateToMonthDateYear(startDate, true);
  const endString = formatDateToMonthDateYear(endDate, true);

  return `${startString} - ${endString}`;
}

export function getWeekRangeObject(date) {
  const currentDate = new Date(date);
  const utcDate = new Date(
    currentDate.getTime() + currentDate.getTimezoneOffset() * 60000,
  );
  const dayOfWeek = utcDate.getDay();
  const startDate = new Date(utcDate);
  startDate.setDate(utcDate.getDate() - dayOfWeek);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(23, 59, 59, 999);
  // format dates to YYYY-MM-DD format
  const fromDate = getLocalDate(startDate);
  const toDate = getLocalDate(endDate);
  return { fromDate, toDate };
}

export function convertTimeToString(time) {
  const { hour, mins } = time;
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12;
  const formattedMinutes = mins < 10 ? `0${mins}` : mins;
  const timeString = `${formattedHour}:${formattedMinutes} ${period}`;
  return timeString;
}

export function convertTimeToInputTime(time) {
  if (!time) return "";
  const { hour, mins } = time;
  return (
    (hour < 10 ? "0" + hour : hour) + ":" + (mins < 10 ? "0" + mins : mins)
  );
}

export function getIndexFromDay(day) {
  switch (day) {
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    default:
      return 0;
  }
}

export function getLocalDate(date) {
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

// Get start and end date of the week based on the date
export function getStartEndDate(date) {
  const currentDate = new Date(date);
  const dayOfWeek = currentDate.getDay();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - dayOfWeek);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(23, 59, 59, 999);
  return { startDate: moment(startDate), endDate: moment(endDate) };
}

export function getStartEndMonth(date) {
  const currentDate = new Date(date);
  const previousMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate(),
  );
  const startDate = new Date(
    previousMonthDate.getFullYear(),
    previousMonthDate.getMonth(),
    previousMonthDate.getDate(),
  );
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  endDate.setHours(23, 59, 59, 999);
  return { startDate: moment(startDate), endDate: moment(endDate) };
}

export function getWeekStartDate(date) {
  const currentDate = new Date(date);
  const dayOfWeek = currentDate.getDay();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - dayOfWeek);
  startDate.setHours(0, 0, 0, 0);
  return moment(startDate).format("MM/DD/YYYY");
}

export const generateWeeks = (startDate, endDate) => {
  const weeks = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    weeks.push(moment(currentDate).format("MM/DD/YYYY"));
    currentDate.setDate(currentDate.getDate() + 7);
  }
  return weeks;
};

export const generateDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(moment(currentDate).format("MM/DD/YYYY"));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export function getWeekday(dateString) {
  var date = new Date(dateString);
  var weekdayIndex = date.getDay();
  var weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdays[weekdayIndex];
}

export function convertStartAndEndDatesToWeekRangeString(startDate, endDate) {
  // week range format is "MMM DD - MMM DD, YYYY"
  const start = new Date(startDate);
  const end = new Date(endDate);
  const startMonth = start.toLocaleString("default", { month: "short" });
  const endMonth = end.toLocaleString("default", { month: "short" });
  const startDay = start.getDate();
  const endDay = end.getDate();
  const year = end.getFullYear();
  return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`;
}

export function formatDateTime(utcTimestamp) {
  const date = new Date(utcTimestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).format(date);
}

export function formatToYYYYMMDD(dateStr) {
  if (!dateStr) return "";
  const [month, day, year] = dateStr.split("/");
  const fullYear = year.length === 2 ? `20${year}` : year;
  return `${fullYear}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

export function amPmconvert(time) {
  if (!time) return "";
  if (time.substr(0, 2) >= 12) {
    if (time.substr(0, 2) == 12) {
      return time + "pm";
    } else {
      return parseInt(time.substr(0, 2)) - 12 + time.substr(2, 6) + "pm";
    }
  } else {
    return time + "am";
  }
}
