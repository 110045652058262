export const CANCELLATION_REASONS = [
  "Illness or Injury",
  "Unable to travel",
  "Other commitments",
  "None",
];

export const CANCELLATION_REASONS_MAP = {
  staff: [
    "Illness or Injury",
    "Unable to travel",
    "Other Commitments",
    "COVID",
    "Advanced Notice",
    "Less Than 24 hours",
    "At the Door/Less than 2 Hours",
    "Sick/Appointment",
    "None",
  ],
  clinic: ["Clinic Closed", "Staff Rescheduling", "None"],
  client: [
    "Illness or Injury",
    "Unable to travel",
    "Other commitments",
    "COVID",
    "Advanced Notice",
    "Less Than 24 hours",
    "At the Door/Less than 2 Hours",
    "Sick/Appointment",
    "Late",
    "None",
  ],
};

// ABAC Rethink list: "COVID","Advanced Notice","Less Than 24 hours","At the Door/Less than 2 Hours","Sick/Appointment"

export const TECH_APPOINTMENT_CATEGORIES = [
  { id: 1, value: "break", title: "Break" },
  { id: 2, value: "admin-time", title: "Admin Time" },
  { id: 3, value: "other", title: "Other" },
  { id: 4, value: "sick", title: "Sick" },
];

export const CLIENT_APPOINTMENT_CATEGORIES = [
  { id: 1, value: "nap", title: "Nap" },
  { id: 2, value: "drive-time", title: "Drive Time" },
  { id: 3, value: "other-therapy", title: "Other Therapy Appointments" },
  { id: 4, value: "other", title: "Other" },
];

export const DRIVE_TIME_SESSION_CODE = {
  id: 1,
  value: "DRIVE",
  title: "Drive Time",
};

export const SESSION_TYPE_MAPPING = {
  97153: "Direct Care",
  97154: "Group Therapy",
  97155: "Supervision",
  97151: "BCBA Assessment",
  NAP: "Nap",
  other: "Other",
  DRIVE: "Drive Time",
  ADMNTM: "Admin Time",
  BREAK: "Break",
  OTHER: "Other Therapy Appointments",
  BCSTSV: "Supervision",
};

export const SESSION_CODE_MAPPING = {
  directCare: "97153",
  directCareTele: "97153TL",
  groupTherapy: "97154",
  groupTherapyBCBA: "97158",
  bcbaAssessment: "97151",
  bcbaSupervision: "97155",
  nbSupervision: "NBSUP",
  parentTraining: "97156",
  nap: "NAP",
  break: "BREAK",
  driveTime: DRIVE_TIME_SESSION_CODE.value,
  holiday: "HOLIDAY",
  closed: "CLOSED",
};

export const BROADCAST_MESSAGES = [
  {
    id: 1,
    title: "Appointment Reminder",
    message: "Please check your upcoming appointments for today.",
  },
  // {
  //   id: 2,
  //   title: "Parent Training",
  //   message:
  //     "Hey {{first_name}}, we'd love to have you in for a parent training session with the {{clinic_name}} team. Use the link below to select the BCBA working with your child that works around your schedule. See you soon!",
  // },
  {
    id: 3,
    title: "Schedule Update",
    message:
      "Your schedule has been updated. Please log into TheraDriver or your EHR to view it.",
  },
  {
    id: 4,
    title: "Clinic Closed",
    message: "The clinic is closed today.",
  },
  {
    id: 5,
    title: "Custom Text",
    message: "",
  },
  {
    id: 6,
    title: "Cancelled by Tech",
    message:
      "Hi, your behavior tech {{techName}}, has cancelled their session with you for {{date}} between {{startTime}} - {{endTime}}",
  },
  {
    id: 7,
    title: "Cancelled by Client",
    message:
      "Hey {{techName}}, unfortunately {{clientName}} has canceled their session for {{date}} between {{startTime}} - {{endTime}}. If this is your first session of the day, please reach out to your Ops / Clinical team. We're doing our best to find you another session -- stay tuned!",
  },
];

export const MIN_SESSION_LENGTH_OPTIONS = [
  { id: 1, value: 1, title: "30 Mins" },
  { id: 2, value: 2, title: "1 Hour" },
  { id: 3, value: 3, title: "1.5 Hours" },
  { id: 4, value: 4, title: "2 Hours" },
];

export const MAX_SESSION_LENGTH_OPTIONS = [
  { id: 1, value: 4, title: "2 Hours" },
  { id: 2, value: 5, title: "2.5 Hours" },
  { id: 3, value: 6, title: "3 Hours" },
  { id: 4, value: 7, title: "3.5 Hours" },
  { id: 5, value: 8, title: "4 Hours" },
  { id: 6, value: 9, title: "4.5 Hours" },
  { id: 7, value: 10, title: "5 Hours" },
  { id: 8, value: 11, title: "5.5 Hours" },
  { id: 9, value: 12, title: "6 Hours" },
];

export const ADDITIONAL_SESSION_CODES = [
  { id: 1, value: "LTARSTF", title: "Late Arrival - Staff" },
  { id: 2, value: "LTARCL", title: "Late Arrival - Client" },
  { id: 3, value: "LATEPCKP", title: "Late Pickup" },
  { id: 4, value: "ERLYDPR", title: "Early Departure - Staff" },
  { id: 5, value: "ERLYPCKP", title: "Early Pickup" },
];

export const DEFAULT_ADDITIONAL_SESSION_OBJECT = {
  sessionCodeId: "LATEPCKP",
  duration: 30,
};

export const CUSTOM_STATUS_OPTIONS = [
  { id: 1, value: null, title: "Auto" },
  { id: 2, value: "clinical_review", title: "Clinical Review" },
  { id: 3, value: "restaff", title: "Restaff" },
  { id: 4, value: "draft", title: "Draft" },
  { id: 5, value: "admin_hold", title: "Admin Hold" },
  { id: 6, value: "caregiver_training", title: "Caregiver Training" },
  { id: 7, value: "pending_auth", title: "Pending Auth" },
  { id: 8, value: "parent_pending", title: "Parent Pending" },
  { id: 9, value: "tech_pending", title: "Tech Pending" },
  { id: 10, value: "clinical_pending", title: "Clinical Pending" },
];
// Order: Evaluation, Assign Tech, Scheduled, Clinical Review, Restaff, Pending, Disabled

// export const CLIENT_STATUS_OPTIONS = [
//   { id: 1, value: "clinical_review", title: "Clinical Review" },
//   { id: 2, value: "evaluation", title: "Evaluation" },
//   { id: 3, value: "hire_tech", title: "Hire Tech" },
//   { id: 4, value: "assign_tech", title: "Assign Tech" },
//   { id: 5, value: "pending", title: "Pending" },
//   { id: 6, value: "scheduled", title: "Scheduled" },
//   { id: 7, value: "restaff", title: "Restaff" },
//   { id: 8, value: "disable", title: "Disabled" },
// ];

export const CLIENT_STATUS_OPTIONS = [
  { id: 1, value: "evaluation", title: "Evaluation" },
  { id: 2, value: "hire_tech", title: "Hire Tech" },
  { id: 3, value: "assign_tech", title: "Assign Tech" },
  { id: 4, value: "scheduled", title: "Scheduled" },
  { id: 5, value: "started", title: "Started" },
  { id: 6, value: "clinical_review", title: "Clinical Review" },
  { id: 7, value: "admin_hold", title: "Admin Hold" },
  { id: 8, value: "restaff", title: "Restaff" },
  { id: 9, value: "draft", title: "Draft" },
  { id: 10, value: "disable", title: "Disabled" },
  { id: 11, value: "caregiver_training", title: "Caregiver Training" },
  { id: 12, value: "pending_auth", title: "Pending Auth" },
  { id: 13, value: "parent_pending", title: "Parent Pending" },
  { id: 14, value: "tech_pending", title: "Tech Pending" },
  { id: 15, value: "clinical_pending", title: "Clinical Pending" },
];

export const CUSTOM_STATUS_STAFF = [
  { id: 1, value: null, title: "Status" },
  { id: 2, value: "pending", title: "Pending" },
  { id: 4, value: "accepted", title: "Accepted" },
];

export const DEFAULT_CLINIC_TIMING = {
  monday: [{ startTime: "09:00", endTime: "17:00" }],
  tuesday: [{ startTime: "09:00", endTime: "17:00" }],
  wednesday: [{ startTime: "09:00", endTime: "17:00" }],
  thursday: [{ startTime: "09:00", endTime: "17:00" }],
  friday: [{ startTime: "09:00", endTime: "17:00" }],
};

export const NO_STAFF = "no-staff";
export const NO_CLIENT = "no-client";
export const HIRING_NEED = "Hiring Need";
export const THERAPY_MODES = {
  HOME: "home",
  CLINIC: "clinic",
};

export const SESSION_NOTE_TYPES = {
  DESCRIPTION: "description",
  CANCEL: "cancel_note",
};

export const FSO_ALGORITHM = {
  DEFAULT: "Default",
  DIVERSE_STAFF_ALLOCATION: "Diverse Staff Allocation",
};

export const EVENT_TYPES = [
  { id: 1, value: "bulk_cancellation", title: "Bulk Cancellation" },
];

export const BULK_CANCELLATION_REASONS = ["Spring Break", "Other", "None"];
export const PENDING_CUTOFF_MINS = 24 * 60;
